@import 'variables';

@import '~bootstrap/scss/bootstrap';

.card-text {
  margin-bottom: 0.25rem;
}

// Custom

.bg-homekit {
  background-color: #eff3e7 !important;
}

.qrcode-container {
  display: inline-block;

  svg {
    width: 100%;
    height: auto;
  }
}

.loading-page {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc( 50% - ( 50px / 2) ); /* where ... is the element's height */
  right: calc( 50% - ( 50px / 2) ); /* where ... is the element's width */
}